import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader, Checkbox, Chip,
    CircularProgress,
    Dialog, DialogContent, Divider, FormControlLabel, Grid, ListItemText,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import useOmniaApi from "../../omnia/hooks/use-omnia-api";
import {useDialog} from "../../omnia/hooks/use-dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import SaveButton from "../../omnia/components/elements/save-button";
import {useTranslation} from "react-i18next";

function LeonardoPreprocessorSettings(){

    const { t } = useTranslation();
    const { get, post, del } = useOmniaApi();
    const [ loading, setLoading ] = useState(true);
    const [ processors, setProcessors ] = useState([]);
    const addDialog = useDialog();

    const processorFormik = useFormik({
        initialValues: {
            name: '',
            description: '',
            leonardo_id: '',
            cover_image: '',
            created_at: '',
            created_by: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(255, 'Name must be 255 characters or less')
                .required('Name is required'),
            description: Yup.string(),
            leonardo_id: Yup.number()
                .required('Leonardo ID is required'),
            cover_image: Yup.mixed(),
            created_at: Yup.date(),
            created_by: Yup.string()
        }),
        onSubmit: (values, helpers) => {
            helpers.setSubmitting(true);
            post('beyond-hub/preprocessors', values).then((newProcessor) => {
                addDialog.handleClose();
                helpers.resetForm();
                setProcessors(prev => [...prev, newProcessor]);
            }).finally(() => {
                helpers.setSubmitting(false);
            })
        }
    })

    const handleRemoveProcessor = (id) => {
        if(window.confirm(t('notify.are_you_sure'))){
            del('beyond-hub/preprocessors', id).then(() => {
                setProcessors(processors.filter(processor => processor.id !== id));
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        get('beyond-hub/preprocessors').then(results => {
            setProcessors(results);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    return (
        <>
            <Card>
                {loading ? (
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <>
                        {processors.length > 0 ? (
                            <Stack spacing={2} p={3} mt={2}>
                                {processors.map((processor, index) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        key={index}
                                        alignItems="center"
                                    >
                                        <Stack direction="row" spacing={4} alignItems="center">
                                            <Chip label={processor.with_strength ? 'Mit Stärke' : 'Ohne Stärke'} variant="outlined" sx={{width: 100}} color="primary" />
                                            <Stack>
                                                <Typography variant="h6">
                                                    {processor.name + ' #' + processor.leonardo_id}
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {processor.description}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Button color="error" variant="outlined" onClick={() => handleRemoveProcessor(processor.id)}>
                                            Löschen
                                        </Button>
                                    </Stack>
                                ))}
                            </Stack>
                        ) : (
                            <div style={{
                                height: 300,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="caption">
                                    Keine Präprozessoren hinzugefügt
                                </Typography>
                            </div>
                        )}
                    </>
                )}
                <Box p={3} mt={4}>
                    <Button color="primary" onClick={addDialog.handleOpen} variant="contained">
                        Hinzufügen
                    </Button>
                </Box>
            </Card>
            <Dialog
                open={addDialog.open}
                maxWidth="md"
                fullWidth
                onClose={addDialog.handleClose}
            >
                <DialogContent>
                    <Typography variant="h4" mb={4}>
                        Neuer Präprozessor
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                fullWidth
                                {...processorFormik.getFieldProps('name')}
                                error={processorFormik.touched.name && !!processorFormik.errors.name}
                                helperText={processorFormik.touched.name && processorFormik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Beschreibung"
                                fullWidth
                                rows={3}
                                maxRows={5}
                                {...processorFormik.getFieldProps('description')}
                                error={processorFormik.touched.description && !!processorFormik.errors.description}
                                helperText={processorFormik.touched.description && processorFormik.errors.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberFormat
                                customInput={TextField}
                                label="Leonardo ID"
                                fullWidth
                                {...processorFormik.getFieldProps('leonardo_id')}
                                error={processorFormik.touched.leonardo_id && !!processorFormik.errors.leonardo_id}
                                helperText={processorFormik.touched.leonardo_id && processorFormik.errors.leonardo_id}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        onChange={(event, val) => {
                                            processorFormik.setFieldValue('with_strength', val);
                                        }}
                                        name="with_strength"
                                        checked={processorFormik.values.with_strength}
                                        value={processorFormik.values.with_strength}
                                    />
                                )}
                                label="Erlaube, eine Stärke festzulegen"
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                    <Stack mt={2} direction="row" justifyContent="space-between">
                        <Button variant="outlined" color="primary" onClick={addDialog.handleClose}>
                            Abbrechen
                        </Button>
                        <SaveButton
                            loading={processorFormik.isSubmitting}
                            onClick={processorFormik.submitForm}
                        />
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default LeonardoPreprocessorSettings;