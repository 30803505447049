import React, {useEffect, useState} from "react";
import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import LinearProgressWithLabel from "./linear-progress-with-label";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import PropTypes from "prop-types";

function UploadingDialog({isUploading, uploadStatus}){

    const [ open, setOpen ] = useState(false);
    const { t } = useTranslation();
    const { isMobile } = useIsMobile();

    useEffect(() => {
        if(isUploading){
            const timer = setTimeout(() => {
                setOpen(isUploading);
            }, 750);
            return () => clearTimeout(timer);
        } else {
            setOpen(false);
        }
    }, [isUploading]);

    return (
        <Dialog open={open && isUploading} fullScreen={isMobile} fullWidth maxWidth="sm">
            <DialogContent>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography variant="h4" color="textPrimary">
                        {uploadStatus === 100 ? t("Processing ...") : t("Uploading ...", {status: uploadStatus})}
                    </Typography>
                    <Box mb={3} mt={2} sx={{width: '100%'}}>
                        <LinearProgressWithLabel value={uploadStatus} />
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Typography variant="body1" color="textPrimary">
                            {t("Please wait for upload")}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                            {t("This can take several minutes")}
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

UploadingDialog.propTypes = {
    isUploading: PropTypes.bool.isRequired,
    uploadStatus: PropTypes.number.isRequired
}

export default UploadingDialog;