import {useEffect, useState} from 'react';
import useOmniaApi from "./use-omnia-api";

export function useGetElement(endpoint, showErrors) {

    const { get } = useOmniaApi({autoError: showErrors});
    const [ loading, setLoading ] = useState(true);
    const [ element, setElement ] = useState(null);

    const reload = () => {
        get(endpoint).then(response => {
            setElement(response);
        }).catch(errors => {
            console.log('Could not load element: ', errors);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if(endpoint){
            setLoading(true);
            reload();
        } else {
            setLoading(false);
        }
    }, [endpoint]);

    return {
        loading,
        setElement,
        reload,
        element
    }
}

useGetElement.defaultProps = {
    showErrors: true
}

export default useGetElement;