import React, {lazy, Suspense} from "react";
import {mergeRoutes} from "src/omnia/routes-composer";
import {Layout as OmniaLayout} from "src/omnia/components/layouts/dashboard/index";
import {Outlet} from "react-router";
import ActivityHeart from "@untitled-ui/icons-react/build/esm/ActivityHeart";
import DashboardIcon from "@untitled-ui/icons-react/build/esm/BarChartCircle01";
import DatabaseIcon from "@untitled-ui/icons-react/build/esm/Database01";
import ProjectsIcon from "@untitled-ui/icons-react/build/esm/CheckDone01";
import ChatIcon from "@untitled-ui/icons-react/build/esm/MessageChatCircle";
import ImageIcon from "@untitled-ui/icons-react/build/esm/Image01";
import SettingsIcon from "@untitled-ui/icons-react/build/esm/Settings04";

import LoadingScreen from "./omnia/components/general/loading-screen";
import AuthGuard from "./omnia/components/general/auth/AuthGuard";
import ServiceGuard from "./omnia/components/general/auth/ServiceGuard";
import ChatWrapper from "./components/beyond-hub/chat-wrapper";
import {ServiceLayout} from "./omnia/components/layouts/service";

// Beyond Hub Backend
const Dashboard = lazy(() => import('src/views/bh-backend/index'));
const Assistants = lazy(() => import('src/views/bh-backend/assistants'));
const Assistant = lazy(() => import('src/views/bh-backend/assistant'));
const Chat = lazy(() => import('src/views/bh-backend/chat'));
const CreateText = lazy(() => import('src/views/bh-backend/create-text'));
const CreateImages = lazy(() => import('src/views/bh-backend/create-images'));
const Certificate = lazy(() => import('src/views/bh-backend/certificate'));

// Beyond Hub Light Service
const BHLDashboard = lazy(() => import('src/views/beyond-hub/index'));
const BHLDatabase = lazy(() => import('src/views/beyond-hub/database'));
const BHLSingleChat = lazy(() => import('src/views/beyond-hub/chat'));
const BHLMyChats = lazy(() => import('src/views/beyond-hub/my-chats'));
const BHLMyImages = lazy(() => import('src/views/beyond-hub/my-images'));
const BHLProjects = lazy(() => import('src/views/beyond-hub/projects'));
const BHLCorporateIdentity = lazy(() => import('src/views/beyond-hub/corporate-identity'));
const BHLSettings = lazy(() => import('src/views/beyond-hub/settings'));

const Demo = lazy(() => import('src/views/demo-view'));

export const getRoutes = () => {

    return mergeRoutes([
        {
            path: '/on/beyond-hub',
            element: (
                <AuthGuard>
                    <OmniaLayout>
                        <Suspense fallback={<LoadingScreen/>}>
                            <Outlet/>
                        </Suspense>
                    </OmniaLayout>
                </AuthGuard>
            ),
            children: [
                {
                    path: '/on/beyond-hub',
                    element: <Dashboard/>
                },
                {
                    path: '/on/beyond-hub/create-text',
                    element: <CreateText/>
                },
                {
                    path: '/on/beyond-hub/chat/:id',
                    element: <Chat/>
                },
                {
                    path: '/on/beyond-hub/create-images',
                    element: <CreateImages/>
                },
                {
                    path: '/on/beyond-hub/assistants',
                    element: <Assistants/>
                },
                {
                    path: '/on/beyond-hub/assistants/:id',
                    element: <Assistant/>
                },
                {
                    path: '/on/beyond-hub/demo',
                    element: <Demo />
                },
                {
                    path: '/on/beyond-hub/create',
                    element: <Demo />
                },
                {
                    path: '/on/beyond-hub/media',
                    element: <Demo />
                },
                {
                    path: '/on/beyond-hub/projects',
                    element: <Demo />
                },
                {
                    path: '/on/beyond-hub/hub/certs',
                    element: <Certificate />
                },
                {
                    path: '/on/beyond-hub/hub/methods',
                    element: <Demo />
                },
            ]
        },
        {
            path: '/beyond-hub',
            element: (
                <ServiceGuard serviceIdentifier={['beyond-hub-light', 'beyond-hub-individual', 'beyond-hub-occhio']}>
                    <ServiceLayout
                        actionButton={{
                            title: 'Noch mehr Infos',
                            subheader: 'Falls du Fragen hast',
                            buttonLabel: 'Mehr Hilfe',
                            buttonPath: '/beyond-hub/my-chats',
                            icon: 'Airpods'
                        }}
                        sections={[
                            {
                                subheader: null,
                                items: [
                                    {
                                        title: "Dashboard",
                                        path: '/beyond-hub',
                                        icon: <DashboardIcon fontSize="small"/>,
                                    },
                                    {
                                        title: "Meine Chats",
                                        path: '/beyond-hub/my-chats',
                                        icon: <ChatIcon fontSize="small"/>,
                                    },
                                    {
                                        title: "Meine Bilder",
                                        path: '/beyond-hub/my-pictures',
                                        icon: <ImageIcon fontSize="small"/>,
                                    },
                                    {
                                        title: "Meine Medien",
                                        path: '/beyond-hub/database',
                                        icon: <DatabaseIcon fontSize="small"/>,
                                    },
                                    {
                                        title: "Meine CI",
                                        path: '/beyond-hub/corporate-identity',
                                        icon: <ActivityHeart fontSize="small"/>,
                                        disabled: true
                                    },
                                    {
                                        title: "Meine Projekte",
                                        path: '/beyond-hub/projects',
                                        icon: <ProjectsIcon fontSize="small"/>,
                                        disabled: true
                                    },
                                    {
                                        title: "Mein Beyond Hub",
                                        path: '/beyond-hub/settings',
                                        icon: <SettingsIcon fontSize="small"/>,
                                        disabled: true
                                    },
                                ],
                            }
                        ]}
                        menuItems={[
                            {
                                title: "Einstellungen",
                                path: '/beyond-hub/settings',
                                icon: <SettingsIcon fontSize="small"/>,
                            },
                        ]}
                    >
                        <ChatWrapper/>
                        <Suspense fallback={<LoadingScreen/>}>
                            <Outlet/>
                        </Suspense>
                    </ServiceLayout>
                </ServiceGuard>
            ),
            children: [
                {
                    path: '/beyond-hub',
                    element: <BHLDashboard/>
                },
                {
                    path: '/beyond-hub/corporate-identity',
                    element: <BHLCorporateIdentity/>
                },
                {
                    path: '/beyond-hub/database',
                    element: <BHLDatabase/>
                },
                {
                    path: '/beyond-hub/projects',
                    element: <BHLProjects/>
                },
                {
                    path: '/beyond-hub/my-chats',
                    element: <BHLMyChats/>
                },
                {
                    path: '/beyond-hub/chat/:id',
                    element: <BHLSingleChat/>
                },
                {
                    path: '/beyond-hub/my-pictures',
                    element: <BHLMyImages/>
                },
                {
                    path: '/beyond-hub/settings',
                    element: <BHLSettings/>
                },
            ]
        }
    ])
}
